import React, { useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Pagination from "./Pagination";
import { userContext } from "../pages/Context";
import NoDataPresent from "./NoDataPresent";
import { Alert } from "react-bootstrap";

const GroupRequests = ({
  data,
  approveGroupRequest,
  rejectGroupRequest,
  approveBulkGroupRequests,
  rejectBulkGroupRequests,
  selectedCountry,
  setSelectedCountry,
  selectedGroup,
  setSelectedGroup,
  groupList,
  selectedRows,
  countries,
  setSelectedRows,
  pagination,
  setPagination,
  totalRecords,
}) => {
  const { user } = useContext(userContext);
  const toggleRowSelection = (selectedItem) => {
    if (selectedRows.some((item) => item.id === selectedItem.id)) {
      setSelectedRows(
        selectedRows.filter((item) => item.id !== selectedItem.id)
      );
    } else {
      setSelectedRows([...selectedRows, selectedItem]);
    }
  };
  const toggleAllRows = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((item) => item));
    }
  };

  const DropdownSearch = React.forwardRef((props, ref) => {
    const { children, style, className, "aria-labelledby": labeledBy } = props;
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <Form.Control
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
          style={{ position: "sticky", top: "0px" }}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child?.props?.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  });

  return (
    <>
      <div
        className="mb-4"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {" "}
        <div style={{ display: "flex" }}>
          {user.role < 3 && (
            <InputGroup className="mx-3" style={{ width: "fit-content" }}>
              <DropdownButton
                variant="outline-secondary"
                title={
                  selectedCountry ? selectedCountry["name"] : "Select Country"
                }
                id="input-group-dropdown-1"
                onSelect={(e) => {
                  setSelectedCountry(JSON.parse(e));
                }}
              >
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {countries.map((country, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(country)} key={index}>
                    {country.name}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </InputGroup>
          )}
          <InputGroup style={{ width: "fit-content" }}>
            <Dropdown
              onSelect={(e) => {
                setSelectedGroup(JSON.parse(e));
              }}
            >
              <Dropdown.Toggle
                variant="outline-secondary"
                id="input-group-dropdown-1"
              >
                {selectedGroup ? selectedGroup.title : "Select Group"}
              </Dropdown.Toggle>
              <Dropdown.Menu as={DropdownSearch}>
                <Dropdown.Item eventKey={undefined}>Select None</Dropdown.Item>
                {groupList.map((group, index) => (
                  <Dropdown.Item eventKey={JSON.stringify(group)} key={index}>
                    {group.title}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        </div>
        <div>
          <Button
            variant="success"
            disabled={selectedRows.length == 0}
            onClick={() =>
              approveBulkGroupRequests(selectedRows, selectedGroup?.id)
            }
          >
            Approve selected
          </Button>
          <Button
            style={{ marginLeft: "1rem" }}
            disabled={selectedRows.length == 0}
            variant="danger"
            onClick={() =>
              rejectBulkGroupRequests(selectedRows, selectedGroup?.id)
            }
          >
            Reject selected
          </Button>
        </div>
      </div>
      <Alert variant="info" style={{ marginTop: "1em" }}>
        <p>Steps:</p>
        <ul>
          <li>Select the group to see it's requests.</li>
          <li>Now, you can approve or reject the request</li>
        </ul>
      </Alert>
      {data.length === 0 ? (
        <NoDataPresent />
      ) : (
        <>
          <Table striped bordered responsive>
            <thead>
              <tr>
                <th className="table-head">
                  <input
                    type="checkbox"
                    checked={
                      selectedRows.length > 0 &&
                      selectedRows.length === data.length
                    }
                    onChange={toggleAllRows}
                  />
                </th>
                <th className="table-head">#</th>
                <th className="table-head">User Name</th>
                <th className="table-head">User Email</th>
                {/* <th className="table-head">Country</th> */}
                <th className="table-head">Chat Group</th>
                <th className="table-head">Date</th>
                <th className="table-head">Approve</th>
                <th className="table-head">Reject</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.some(
                        (sitem) => sitem.id === item.id
                      )}
                      onChange={() => toggleRowSelection(item)}
                    />
                  </td>
                  <td>{pagination.offset + index + 1}</td>
                  <td>
                    {item["public_user.firstName"] +
                      " " +
                      item["public_user.lastName"]}
                  </td>
                  <td>{item["public_user.email"]}</td>
                  {/* <td>{item["chatGroup.Countries.name"]}</td> */}
                  <td>{item["chatGroup.title"]}</td>
                  <td>
                    {new Date(item.createdAt).toLocaleDateString("en-US", {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                    })}
                  </td>
                  <td>
                    <Button
                      variant="success"
                      className="rounded-pill"
                      onClick={() =>
                        approveGroupRequest(
                          item["public_user.id"],
                          item["chatGroup.id"]
                        )
                      }
                      disabled={selectedRows.some(
                        (sitem) => sitem.id === item.id
                      )}
                    >
                      Approve
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="rounded-pill"
                      onClick={() =>
                        rejectGroupRequest(
                          item["public_user.id"],
                          item["chatGroup.id"]
                        )
                      }
                      disabled={selectedRows.some(
                        (sitem) => sitem.id === item.id
                      )}
                    >
                      Reject
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            setPagination={setPagination}
            totalRecords={totalRecords}
          />
        </>
      )}
    </>
  );
};

export default GroupRequests;
